import React, { useCallback, useEffect, useState } from "react";
import { DataFrame, FieldType, Field, AnnotationEventUIModel, QueryVariableModel} from "@grafana/data";
import { EquipmentFeedbackPanelProps } from "components/EquipmentFeedbackPanel";
import { DataGrid, GridColDef, GridRowId, GridActionsCellItem, GridRowModes, GridRowModesModel, GridEditSingleSelectCellProps, GridEditSingleSelectCell, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid";
import { format } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import SendIcon from '@mui/icons-material/Send';
import { severityOptions,featureOptions,anomalyTypeOptions,trendOptions, equipmentOperationOptions,} from "utils/optionsUtils";
import { Button, Chip } from "@mui/material";
import { handleCancelClick, handleDeleteRow, handleEditClick, handleRowEditStop, handleSaveClick } from "utils/dataGridUtils";
import ConfirmationModal from "./ConfirmationModal";
import { getTemplateSrv } from "@grafana/runtime";
import { usePanelContext } from "@grafana/ui";
import useAsyncFn from "react-use/lib/useAsyncFn";

interface AnnotationsPanelProps extends EquipmentFeedbackPanelProps {}

const AnnotationsPanel = (props: AnnotationsPanelProps) => {

  const { data, width, height } = props;
  const variables = getTemplateSrv().getVariables();
  const variablesMap = Object.fromEntries(
    variables.map((it: any) => [it.id, it])
  );
  const equipmentId = variablesMap["Equipment"].current.text.split('-')[1] || "";
  const selectedComponent = (variablesMap.Component as QueryVariableModel).current.value as string || "";
  const { canAddAnnotations, canEditAnnotations, canDeleteAnnotations, ...panelCtx } = usePanelContext();
  const [tags] = useState<string[] | undefined>(undefined);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<GridRowId | null>(null);
  const [annotationIdToDelete, setAnnotationIdToDelete] = useState<string | null>(null);
  const [MeasurePointId, setMeasurePointId] = useState<Field | undefined>(undefined);


  const emptyDataFrame: DataFrame = {
    fields: [
      { name: 'time', type: FieldType.time, values: [], config: {} },
      { name: 'value', type: FieldType.number, values: [], config: {} },
    ],
    length: 0,
  };

  const getFeatureFromTags = (tagValues: string[][], feature: string): string[] => {
    return tagValues.map(inArr => inArr.find(item => item.startsWith(`${feature}:`))).map(it => it?.split(":")[1] || "n/a");
  }

  const constructNewFieldFromTags = (inField: Field, displayName: string, feature: string, index: number): Field => {
      return {
        name: displayName,
        type: FieldType.string,
        values: getFeatureFromTags(inField.values, feature),
        state: {displayName: displayName, multipleFrames: false, seriesIndex: index},
        config: { custom: {}},
        display: inField.display
      };
  }

  const prepareAnnotationsData = (data: DataFrame | undefined): { data: DataFrame; annotationId: Field; MeasurePointId: Field } => {
    if (!data) {
      return { data: emptyDataFrame, annotationId: {} as Field, MeasurePointId: {} as Field};
    }
    const newFields: { [key: string]: Field } = {};

    const Annotationid = data.fields?.find((field) => field.name === 'id')!;
    const timeStartField = data.fields?.find((field) => field.name === 'time')!;
    const timeEndField = data.fields.find((field) => field.name === 'timeEnd')!;
    const textField = data.fields.find((field) => field.name === 'text')!;
    const tagsField = data.fields.find((field) => field.name === 'tags')!;
    const MeasurepointId = constructNewFieldFromTags(tagsField, "mp", "mp",12);

    newFields["Time Start"] = { ...timeStartField, name: "Time Start", type: FieldType.time , values: timeStartField.values.map(it => format(it, 'yyyy-MM-dd HH:mm:ss')) };
    newFields["Time End"] = { ...timeEndField, name: "Time End", type: FieldType.time, values: timeEndField.values.map(it => format(it, 'yyyy-MM-dd HH:mm:ss')) };
    newFields["Component"] = constructNewFieldFromTags(tagsField, "Component", "component", 3);
    newFields["Severity"] = constructNewFieldFromTags(tagsField, "Severity", "severity", 4);
    newFields["Type"] = constructNewFieldFromTags(tagsField, "Type", "anomalyType", 5);
    newFields["Trend"] = constructNewFieldFromTags(tagsField, "Trend", "trend", 6);
    newFields["Feature"] = constructNewFieldFromTags(tagsField, "Feature", "feature", 7);
    newFields["Description"] = {...textField, name: "Description"};
    newFields["Equipment Operation"] = constructNewFieldFromTags(tagsField, "Equipment Operation", "equipmentOperation", 8);
    newFields["Labeler"] = constructNewFieldFromTags(tagsField, "Labeler", "labeler", 9);
    newFields["Customer Interaction"] = constructNewFieldFromTags(tagsField, "Customer Interaction", "customerInteraction", 10);
    newFields["Alert Lifecycle"] = constructNewFieldFromTags(tagsField, "Alert Lifecycle", "alert", 11);
    return {
      data: {
        fields: Object.values(newFields),
        length: data.length,
      },
      annotationId: Annotationid,
      MeasurePointId: MeasurepointId
    };
  };
  const [tableData, setTableData] = useState<DataFrame>(emptyDataFrame);
  const [annotationId, setAnnotationId] = useState<Field | undefined>(undefined);

  useEffect(() => {
    if (data.annotations && data.annotations.length > 0) {
      const { data: preparedData, annotationId: id,  MeasurePointId } = prepareAnnotationsData(data.annotations[0]);
      setTableData(preparedData);
      setAnnotationId(id);
      setMeasurePointId(MeasurePointId)
    }
  }, [data.annotations]);

  const openModal = (id: GridRowId) => {
    const annotationIdValue = annotationId?.values[Number(id)];
    setRowToDelete(id);
    setAnnotationIdToDelete(annotationIdValue);  
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setRowToDelete(null);
  };

  const onAnnotationDelete = useCallback((annotationId: string) => {
    if (panelCtx.onAnnotationDelete) {
      panelCtx.onAnnotationDelete(annotationId);
    }
  }, [panelCtx]);
  
  const confirmDelete = () => {
    if ((rowToDelete !== null && annotationIdToDelete !== null)) {
      onAnnotationDelete(annotationIdToDelete);
      handleDeleteRow(rowToDelete, tableData, setTableData);
      closeModal();
    }
  };
  
  const renderSelectEditCell = (props: GridEditSingleSelectCellProps) => {
    return <GridEditSingleSelectCell {...props} />;
  };

  const renderAlertLifecycleChip = (value: string) => {
    let chipProps = {
      label: value,
      icon: value === "firing" ? <ErrorIcon style={{ color: "white" }} /> : <DoneIcon style={{ color: "white" }}/>,
      style: {
        backgroundColor: value === "firing" ? "#FF6961" : "#C7C8CC",
        color: "white",
      },
    };
    return <Chip {...chipProps} />;
  };

  const renderCustomerInteractionButton = (params: GridRenderCellParams) => {
    const isSent = params.value === 'Sent' ||  params.value === "acknowledged";
    return (
      <Button
        variant="contained"
        size="small"
        startIcon={isSent ? <DoneIcon /> : <SendIcon />}
        disabled={isSent}
        sx={{
          backgroundColor: isSent ? '#C7C8CC' : 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: isSent ? '#C7C8CC' : 'primary.dark',
          },
          padding: '2px 4px',
          fontSize: '0.65rem'
        }}
        onClick={() => handleCustomerInteractionChange(params.id)}
      >
        {isSent ? 'Sent' : 'Send Alert'}
      </Button>
    );
  };

  const triggerEvent = (updatedRow: GridRowModel,tags: string[] | undefined, eventId: string, MeasurePointId: string) => {
    updateAnnotation({
      id: eventId,
      tags: [
        `severity:${updatedRow.Severity || 'N/A'}`,
        `anomalyType:${updatedRow.Type || 'N/A'}`,
        `component:${updatedRow?.["Component"]}`,
        `trend:${updatedRow.Trend || 'N/A'}`,
        `feature:${updatedRow.Feature || 'N/A'}`,
        `equipmentOperation:${updatedRow?.["Equipment Operation"]}`,
        `labeler:${updatedRow?.["Labeler"]}`,
        `mp:${MeasurePointId}`,
        ...new Set(tags),
      ],
      description: updatedRow.Description || "No description provided",
      from: new Date(updatedRow?.["Time Start"]).getTime(),
      to: new Date(updatedRow?.["Time End"]).getTime(),
    });
  }
  
  const handleCustomerInteractionChange = async (id: GridRowId) => {
    const updatedFields = tableData.fields.map(field => {
        if (field.name === 'Customer Interaction' || field.name === 'Alert Lifecycle') {
            const updatedValues = field.values.map((value, index) => {
                if (index === Number(id)) {
                    if (field.name === 'Customer Interaction') {
                        return 'Sent';
                    }
                    if (field.name === 'Alert Lifecycle') {
                        return 'firing';
                    }
                }
                return value;
            });
            return { ...field, values: updatedValues };
        }
        return field;
    });
    setTableData({ ...tableData, fields: updatedFields });

    const updatedRow: GridRowModel = tableData.fields.reduce((acc, field) => {
        const value = field.values[Number(id)];
        if (value !== undefined) {
            acc[field.name] = value;
        }
        return acc;
    }, {} as GridRowModel);

    const updatedTags = [
        ...new Set(tags?.filter(tag => !tag.startsWith("customerInteraction:"))),
        "customerInteraction:Sent",
        "alert:firing", 
        "alert"
    ];
    const eventId = annotationId?.values[Number(id)];
    const measurePointId = MeasurePointId?.values[Number(id)];
    triggerEvent(updatedRow, updatedTags, eventId,measurePointId);
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = newRow;
    tags?.push("customerInteraction:Saved");
    const eventId=annotationId?.values[Number(updatedRow.id)]
    const measurePointId = MeasurePointId?.values[Number(updatedRow.id)];
    triggerEvent(updatedRow,tags,eventId,measurePointId);
    return newRow; 
  };
  
  const [updateAnnotationState, updateAnnotation] = useAsyncFn(
    async (event: AnnotationEventUIModel) => {
      const result = await panelCtx.onAnnotationUpdate!(event);
      return result;
    }
  );

  const columns: GridColDef[] = [
    ...tableData.fields.map(field => {
      let colDef: GridColDef = {
        field: field.name,
        headerName: field.name,
        width: 100,
        editable: field.name !== "Component",
        sortable: true,
        flex: 1,
      };
      if (field.name === "Severity" || field.name === "Trend" || field.name === "Type" || field.name === "Feature" || field.name === "Equipment Operation") {
        colDef = {
          ...colDef,
          type: 'singleSelect',
          valueOptions: field.name === "Severity" ? severityOptions :
                        field.name === "Trend" ? trendOptions :
                        field.name === "Feature" ? featureOptions :
                        field.name === "Equipment Operation"? equipmentOperationOptions :
                        anomalyTypeOptions,
          renderEditCell: renderSelectEditCell,
        };
      }
      if (field.name === "Time Start" || field.name === "Time End" ) {
        colDef = {
          ...colDef,
          type:'dateTime',
          valueGetter: (params) => {
            return params ? new Date(params) : null;
          }
        };
      }
      if (field.name === "Alert Lifecycle") {
        colDef = {
          ...colDef,
          editable:false,
          renderCell: (params) => renderAlertLifecycleChip(params.value),
        };      
      }
      if (field.name === 'Customer Interaction') {
        colDef = {
          ...colDef,
          renderCell: renderCustomerInteractionButton,
          editable: false
        };
      }
      return colDef;
    }),
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={ handleSaveClick(id,rowModesModel, setRowModesModel)}
              />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id, rowModesModel, setRowModesModel, rows, tableData, setTableData)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, rowModesModel, setRowModesModel)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => openModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const rows = Array.from({ length: tableData.length }).map((_, index) => {
    const row: { id: string; [key: string]: any } = { id: index.toString() };
    tableData.fields.forEach(field => {
      row[field.name] = field.values[index];
    });
    return row;
  }).filter(row => {
    const component = row["Component"];
    return component== selectedComponent && component.includes(equipmentId);
  });
 
  return (
    <div className={"AnnotationsTable"}>
      <div style={{ width: `${width}px`, height: `${height}px` }}>
        <DataGrid
          columns={columns}
          rows={rows}
          rowModesModel={rowModesModel}
          editMode="row"
          processRowUpdate={(newRow) => processRowUpdate(newRow)}
          onRowModesModelChange={newModel => setRowModesModel(newModel)}
          getRowId={(row) => row.id}
          onRowEditStop={handleRowEditStop}
        />
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          onConfirm={confirmDelete}
          onCancel={closeModal}
        />
      )}
    </div>
  );
};

  export default AnnotationsPanel;
